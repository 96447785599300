<template>
  <!-- Filter the Transactions by type -->
  <b-dropdown
    :dropup="dropup"
    variant="primary"
    no-caret
    text="Actions Rapides"
    class="m-auto p-0"
    :style="
      dropup
        ? 'position: fixed !important; top: 76%; left: 80%;right: 0% !important; width: 40px !important; height: 45px !important;'
        : ''
    "
  >
    <template #button-content>
      <div class="d-flex justify-content-between justify-content-center rounded-circle">
        <span style="font-size: 12px; text-transform: uppercase" class="">
          {{ !dropup ? $t("message.navbar.fast_action") : "" }}
        </span>
        <feather-icon
          size="14"
          :class="!dropup ? 'ml-50 ' : ''"
          :icon="!dropup ? 'ChevronDownIcon' : 'PlusIcon'"
        />
      </div>
    </template>
    <b-dropdown-item
      v-for="link in menuLinks"
      :key="link.id"
      :to="{ name: link.address }"
    >
      <feather-icon :icon="link.icon" class="mr-50" />
      <span v-if="!dropup">{{ link.label }}</span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { onMounted, onUpdated, reactive, computed, ref } from "@vue/composition-api";
import vSelect from "vue-select";
import URL from "@/views/pages/request/index";
import axios from "axios";
import { BDropdown } from "bootstrap-vue";

export default {
  components: {
    vSelect,
    BDropdown,
  },

  props: ["dropup"],
  setup(props, { root }) {
    const menuLinks = ref([
      {
        id: 0,
        icon: "FileTextIcon",
        address: "FactureAdd",
        label: root.$t("message.navbar.createInvoice"),
      },
      {
        id: 1,
        icon: "FileMinusIcon",
        address: "DevisAdd",
        label: root.$t("message.navbar.createQuote"),
      },
      {
        id: 2,
        icon: "CornerRightDownIcon",
        address: "depense-simple",
        label: root.$t("message.navbar.createExpense"),
      },
      {
        id: 3,
        icon: "ShoppingBagIcon",
        address: "creation-article",
        label: root.$t("message.navbar.createArticle"),
      },
      {
        id: 4,
        icon: "UserIcon",
        address: "creation-client",
        label: root.$t("message.navbar.createClient"),
      },
    ]);
    return { menuLinks };
  },
};
</script>

<style scoped></style>
