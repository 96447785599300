<template>
  <p class="clearfix mb-0">

    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://www.qenium.com"
        target="_blank"
      >Qenium</b-link>
      <span class="d-none d-sm-inline-block">, Tous droits réservés</span>
    </span>

     <span class="float-md-left d-block d-md-inline-block ml-1 mt-25" style="font-size:11px">
     <b-link
      @click="redirection2"
      :class="valideStatus ? 'text-danger' : 'n'"
      >Conditions génerales d'utilisation</b-link>
    </span>

    <span class="float-md-left d-block d-md-inline-block ml-1 mt-25" style="font-size:11px">
     <b-link
      @click="redirection"
      :class="valideStatus ? 'text-danger' : 'n'"
      >Conditions générales de vente</b-link>
    </span>


    <span class="float-md-left d-block d-md-inline-block ml-1 mt-25" style="font-size:11px">
     <b-link
      @click="redirection1"
      :class="valideStatus ? 'text-danger' : 'n'"
      font-size="12"
      >Politique de confidentialité</b-link> <br>
    </span>


    <span class="float-md-right d-none d-md-block">Conçu avec soins &amp; conçu avec
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span>

  </p>

</template>

<script>
import { BLink } from 'bootstrap-vue'
import URL, { APP_HOST } from '@/views/pages/request';

export default {
  components: {
    BLink,
  },
  data() {
    return {
      APP_HOST: APP_HOST,
      valideStatus: false
    }
  },
  methods:{

    redirection() {
      location.assign(APP_HOST + '/cgv.html');
    },
    redirection1() {
      location.assign(APP_HOST + '/politiqueConfidentialite.html');
    },
    redirection2() {
      location.assign(APP_HOST + '/cgu.html');
    },

  }
}
</script>
