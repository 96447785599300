import {qt} from './../../translate/index';


const __sidebar = [
    
        
        {
            "title": qt().sidebar.dashboard,
            "route": "home",
            "icon": "HomeIcon"
          },
         
          {
            'title' : qt().sidebar.billing.header,
            'icon' : "FileTextIcon",
            'children' : [
                {
                    'title' : qt().sidebar.billing.invoice,
                    'route' : "FactureList",
                    'icon' : "FileTextIcon",
                    'params' : 'facture',
                },
                {
                  'title' : qt().sidebar.billing.quotes,
                  'route' : "DevisList",
                  'icon' : "FileMinusIcon",
                  'params' : 'facture',
              },
              {
                'title' : qt().sidebar.billing.relance,
                'route' : "relance",
                'icon' : "RefreshCcwIcon",
                'params' : 'facture',
            },
            {
              'title' : qt().sidebar.billing.taxe,
              'route' : "taxes",
              'icon' : "DivideSquareIcon",
              'params' : 'facture',
          }
            ]
        },
        {
            "title": qt().sidebar.stock_management.header,
            "icon": "LayersIcon",
            "children": [
              {
                "title": qt().sidebar.stock_management.my_stocks,
                "route": "gsMyStocks",
                "icon": "ServerIcon"
              },
              {
                "title": qt().sidebar.stock_management.my_sales,
                "route": "gsMySales",
                "icon": "ShoppingCartIcon"
              },
              {
                "title": qt().sidebar.stock_management.my_supply,
                "route": "gsMySupply",
                "icon": "TruckIcon"
              },
              {
                "title": qt().sidebar.stock_management.supplyThreshold,
                "route": "gsSupplyThreshold",
                "icon": "AlertOctagonIcon"
              },
              {
                "title": qt().sidebar.stock_management.inventaire,
                "route": "inventaire",
                "icon": "ClipboardIcon"
              },
            ]
          },

          {
            "title": qt().sidebar.Treasury.header,
            "icon": "DollarSignIcon",
            "children": [
              {
                "title": qt().sidebar.Treasury.expense,
                "route": "depenses",
                "icon": "CornerLeftDownIcon",
                "children": [
                  {
                    "title": qt().sidebar.Treasury.simple_expense,
                    "route": "liste-depense-simple",
                    "icon": "CornerLeftDownIcon"
                  },
                  {
                    "title": qt().sidebar.Treasury.type_expense,
                    "route": "type-depense",
                    "icon": "ListIcon"
                  }
                ]
              },
              {
                "title": qt().sidebar.Treasury.transaction,
                "route": "transaction",
                "icon": "TrendingUpIcon"
              },
              {
                "title": qt().sidebar.Treasury.accounts,
                "route": "comptes",
                "icon": "LockIcon"
              },
              {
                "title": qt().sidebar.Treasury.loan,
                "route": "emprunt",
                "icon": "CornerRightUpIcon"
              }
            ]
          },
          {
            "title": qt().sidebar.catalog.header,
            "icon": "ShoppingBagIcon",
            "children": [
              {
                "title": qt().sidebar.catalog.catalog,
                "route": "catalogue",
                "icon": "CopyIcon"
              },
              {
                "title": qt().sidebar.catalog.category,
                "route": "CategorieArticles",
                "icon": "GridIcon"
              },
              {
                "title": qt().sidebar.catalog.article,
                "route": "articles",
                "icon": "ShoppingBagIcon"
              }
            ]
          },
          {
            "title": qt().sidebar.crm.header,
            "icon": "UsersIcon",
            "children": [
              {
                "title": qt().sidebar.crm.client,
                "route": "clients",
                "icon": "UserCheckIcon"
              },
              {
                "title": qt().sidebar.crm.provider,
                "route": "fournisseurs",
                "icon": "TruckIcon"
              },
              {
                "title": qt().sidebar.crm.prospect,
                "route": "prospect",
                "icon": "MehIcon"
              },
              {
                "title": qt().sidebar.crm.prospecting,
                "route": "liste-prospection",
                "icon": "Minimize2Icon"
              }
            ]
          },
          {
            "title": qt().sidebar.company.header,
            "icon": "BriefcaseIcon",
            "children": [
              {
                "title": qt().sidebar.company.presonal,
                "route": "employes",
                "icon": "UserIcon"
              },
              {
                "title": qt().sidebar.company.project,
                "route": "projet",
                "icon": "PlusSquareIcon"
              }
            ]
          },
           {
            "title": qt().sidebar.setting.header,
            "icon": "SettingsIcon",
            "children": [
              {
                "title": qt().sidebar.setting.permissions,
                "route": "employes",
                "icon": "KeyIcon"
              },
            ]
          },
          {
            "title": "Ediqia",
            "icon": "ZapIcon",
            "children": []
          }
       
]

export default __sidebar
