<template>
  <!-- Filter the Transactions by type -->
  <div class="">
    <b-modal
      id="modal-feedback-created"
      cancel-variant="outline-secondary"
      ok-title="Créer"
      cancel-title="Annuler"
      centered
      title="Rapport d'erreur et suggestion de fontionnalité"
      @close="closeModal"
    >
      <b-form>
        <!-- Libellé -->
        <b-form-group label="Libellé">
          <template #label> Sujet <span class="text-danger">*</span> </template>

          <b-form-input
            id="libelle"
            v-model="newFeedBack.suject"
            name="libelle"
            placeholder="Ex: J'aimerai une nouvelle fonctionnalité"
          />
          <span
            class="text-danger"
            style="font-size: 12px"
            v-if="errorInput.path === 'libelle'"
          >
            {{ errorInput.message }}
          </span>
        </b-form-group>

        <!-- Description du departement -->
        <b-form-group>
          <label for="taxeValue">Description </label>
          <b-form-textarea
            id="textarea"
            v-model="newFeedBack.description"
            placeholder="Soyez plus explicite afin de nous aider à améliorer notre service..."
            rows="5"
            max-rows="6"
          >
          </b-form-textarea>
          <span
            class="text-danger"
            style="font-size: 12px"
            v-if="errorInput.path === 'description'"
          >
            {{ errorInput.message }}
          </span>
        </b-form-group>
      </b-form>

      <template #modal-footer>
        <b-button
          :disabled="state.loading === true ? true : false"
          variant="primary"
          @click.prevent="createFeedBack"
        >
          <span v-if="state.loading === false">Envoyer</span>
          <b-spinner v-if="state.loading === true" label="Spinning"></b-spinner>
        </b-button>
      </template>
    </b-modal>

    <b-button
      v-b-modal.modal-feedback-created
      id="sendMe-feedBack"
      class="p-0"
      style="
        position: fixed;
        top: 80%;
        left: 90%;
        right: 0% !important;
        border-radius: 50% !important;
        width: 50px !important;
        height: 50px !important;
      "
    >
      <feather-icon size="24" icon="MessageCircleIcon" />
    </b-button>
    <b-tooltip
      title="Envoyer un message"
      class="cursor-pointer"
      :target="`sendMe-feedBack`"
    />
  </div>
</template>

<script>
import { onMounted, onUpdated, reactive, computed, ref } from "@vue/composition-api";
import vSelect from "vue-select";
import URL from "@/views/pages/request/index";
import axios from "axios";
import { BDropdown } from "bootstrap-vue";
import { toast_feedback } from "@/utils/qToast";

export default {
  components: {
    vSelect,
    BDropdown,
  },

  props: ["dropup"],
  setup(props, { root }) {
    const state = reactive({
      loading: false,
    });
    const errorInput = reactive({
      path: "",
      message: "",
    });
    const newFeedBack = reactive({
      suject: "",
      description: "",
    });

    const validateInput = () => {
      const error = {
        path: "",
        message: "",
      };
      if (newFeedBack.suject === "") {
        errorInput.path = "libelle";
        errorInput.message = "Veuillez saisir un sujet";
        return false;
      } else if (newFeedBack.description === "") {
        errorInput.path = "description";
        errorInput.message = "Veuillez saisir une description";
        return false;
      }
      return;
    };

    const closeModal = () => {
      state.loading = false;
      errorInput.path = "";
      errorInput.message = "";
      newFeedBack.suject = "";
      newFeedBack.description = "";
    };

    const createFeedBack = async () => {
      try {
        if (newFeedBack.suject === "") {
          errorInput.path = "libelle";
          errorInput.message = "Veuillez saisir un sujet";
          return false;
        } else if (newFeedBack.description === "") {
          errorInput.path = "description";
          errorInput.message = "Veuillez saisir une description";
          return false;
        } else {
          state.loading = true;
          const { data } = await axios.post(URL.FEEDBACK_CREATE, {
            libelle: newFeedBack.suject,
            text: newFeedBack.description,
          });

          if (data) {
            newFeedBack.suject = "";
            newFeedBack.description = "";
            root.$bvModal.hide("modal-feedback-created");
            toast_feedback(
              root,
              "success",
              "top-right",
              "Votre message a été envoyé avec succès"
            );
            state.loading = false;
            newFeedBack.suject = "";
            newFeedBack.description = "";
            errorInput.path = "";
            errorInput.message = "";
          }
        }
      } catch (error) {
        state.loading = false;
        console.log(error);
      }
    };

    return { newFeedBack, errorInput, state, createFeedBack, closeModal };
  },
};
</script>

<style scoped></style>
